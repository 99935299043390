<template>
  <div>
    <v-card class="mx-auto" width="100%">
      <v-card-title class="headline"
        >Registro General de Pesca de San Andrés Islas</v-card-title
      >

      <v-row>
        <v-col cols="6">
          <v-card color="indigo darken-3" dark class="mx-3">
            <div>
              <v-card-title class="title"
                >Embarcaciones Artesanales</v-card-title
              >

              <v-card-subtitle></v-card-subtitle>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-avatar size="40%">
                <v-img
                  :src="require('@/assets/media/rpg/iconos-pesca_2.png')"
                ></v-img>
              </v-avatar>

              <v-card-title class="display-2 mr-6" v-text="numberOfArtesanales">
              </v-card-title>
            </div>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card color="indigo darken-3" dark class="mx-3">
            <div>
              <v-card-title class="title"
                >Embarcaciones Industriales con Permiso</v-card-title
              >

              <v-card-subtitle></v-card-subtitle>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-avatar size="40%">
                <v-img
                  :src="require('@/assets/media/rpg/iconos-pesca_1.png')"
                ></v-img>
                <!-- <span class="white--text display-2">CJ</span> -->
              </v-avatar>
              <v-card-title
                class="display-2 mr-6"
                v-text="numberOfIndustriales"
              >
              </v-card-title>
            </div>
          </v-card>
        </v-col>

        <!-- </v-row> -->

        <v-col cols="4">
          <v-card color="#ffbb00" dark class="mx-3">
            <div>
              <v-card-title class="title">Pescadores Artesanales Vigentes</v-card-title>

              <v-card-subtitle></v-card-subtitle>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-avatar size="40%">
                <v-img
                  :src="require('@/assets/media/rpg/iconos-pesca_3.png')"
                ></v-img>
              </v-avatar>

              <v-card-title
                class="display-2 mr-6"
                v-text="numberOfFishermanArt"
              >
              </v-card-title>
            </div>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card color="#ffbb00" dark class="mx-3">
            <div>
              <v-card-title class="title">Pescadores Industriales</v-card-title>

              <v-card-subtitle></v-card-subtitle>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-avatar size="40%">
                <v-img
                  :src="require('@/assets/media/rpg/iconos-pesca_3.png')"
                ></v-img>
                <!-- <span class="white--text display-2">CJ</span> -->
              </v-avatar>
              <v-card-title
                class="display-2 mr-6"
                v-text="numberOfFishermanInd"
              >
              </v-card-title>
            </div>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card color="#ffbb00" dark class="mx-3">
            <div>
              <v-card-title class="title">Pescadores Deportivos</v-card-title>

              <v-card-subtitle></v-card-subtitle>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-avatar size="40%">
                <v-img
                  :src="require('@/assets/media/rpg/iconos-pesca_3.png')"
                ></v-img>
                <!-- <span class="white--text display-2">CJ</span> -->
              </v-avatar>
              <v-card-title
                class="display-2 mr-6"
                v-text="numberOfFishermanDep"
              >
              </v-card-title>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// import Highcharts3D from "highcharts/highcharts-3d";

// import Highcharts from "highcharts";
import { getHomeInformation } from "./../config.js";
//import loadCilynder from "highcharts/modules/cylinder.js";

//loadCilynder(Highcharts);
// Highcharts3D(Highcharts);

// var options1 = {
//   chart: {
//     plotBackgroundColor: null,
//     plotBorderWidth: null,
//     plotShadow: false,
//     type: "pie"
//   },
//   title: {
//     text: "Permisos de pescas Industrial, 2019"
//   },
//   tooltip: {
//     pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
//   },
//   plotOptions: {
//     pie: {
//       allowPointSelect: true,
//       cursor: "pointer",
//       dataLabels: {
//         enabled: false
//       },
//       showInLegend: true
//     }
//   },
//   series: [
//     {
//       name: "Solicitudes",
//       colorByPoint: true,
//       data: [
//         {
//           name: "Concepto Tecnico",
//           y: 60.0,
//           sliced: true,
//           selected: true
//         },
//         {
//           name: "Inpección Industrial",
//           y: 20.0
//         },
//         {
//           name: "Ceritificado de Patentes",
//           y: 10.0
//         },
//         {
//           name: "Investigación por violacion al estatuto",
//           y: 10.0
//         }
//       ]
//     }
//   ]
// };

// var options2 = {
//   chart: {
//     type: "column",
//     options3d: {
//       enabled: true,
//       alpha: 10,
//       beta: 25,
//       depth: 70
//     }
//   },
//   title: {
//     text: "No. de barcos / Puerto de desembarco autorizado en Colombia"
//   },
//   /*subtitle: {
//     text: "Notice the difference between a 0 value and a null point"
//   },*/
//   plotOptions: {
//     column: {
//       depth: 25,
//       colorByPoint: true
//     }
//   },
//   xAxis: {
//     //categories: Highcharts.getOptions().lang.shortMonths,
//     categories: [
//       "Enero",
//       "Febrero",
//       "Marzo",
//       "Abril",
//       "Mayo",
//       "Junio",
//       "Julio",
//       "Agosto",
//       "Septiembre",
//       "Octubre",
//       "Noviembre",
//       "Diciembre"
//     ],
//     labels: {
//       skew3d: true,
//       style: {
//         fontSize: "16px"
//       }
//     }
//   },
//   yAxis: {
//     title: {
//       text: null
//     }
//   },
//   series: [
//     {
//       name: "Barcos",
//       data: [2, 3, null, 4, 0, 5, 1, 4, 6, 3, 10, 21]
//     }
//   ]
// };

// var options3 = {
//   chart: {
//     type: "column",
//     options3d: {
//       enabled: true,
//       alpha: 10,
//       beta: 25,
//       depth: 70
//     }
//   },
//   title: {
//     text: "No. de barcos / Arte de pesca"
//   },
//   /*subtitle: {
//     text: "Notice the difference between a 0 value and a null point"
//   },*/
//   plotOptions: {
//     column: {
//       depth: 25
//       //colorByPoint: true
//     }
//   },
//   xAxis: {
//     //categories: Highcharts.getOptions().lang.shortMonths,
//     categories: [
//       "Enero",
//       "Febrero",
//       "Marzo",
//       "Abril",
//       "Mayo",
//       "Junio",
//       "Julio",
//       "Agosto",
//       "Septiembre",
//       "Octubre",
//       "Noviembre",
//       "Diciembre"
//     ],
//     labels: {
//       skew3d: true,
//       style: {
//         fontSize: "16px"
//       }
//     }
//   },
//   yAxis: {
//     title: {
//       text: null
//     }
//   },
//   series: [
//     {
//       name: "Barcos",
//       color: "#553111",
//       data: [12, 3, 23, 41, 0, 5, 1, 46, 6, 3, 10, 21]
//     }
//   ],

// };

export default {
  beforeCreate() {
    this.axios.get(getHomeInformation).then((resp) => {
      // /*
      // for (var i = 0; i < resp.data.ti.length; i++) {
      //   /*
      //   this.chartOptionsIT.series[0].data.push(resp.data.ti[i]);
      //   this.chartOptionsCT.series[0].data.push(resp.data.ct[i]);
      //   this.chartOptionsCP.series[0].data.push(resp.data.cp[i]);
      //   this.chartOptionsSV.series[0].data.push(resp.data.sv[i]);
      // }
      //   this.chartOptionsIT.title.text = 'Inspecciones Técnicas por Meses - Total:'+resp.data.ti_total;
      //   this.chartOptionsCT.title.text = 'Conceptos Técnicos por Meses - Total:'+resp.data.ct_total;
      //   this.chartOptionsCP.title.text = 'Certificados de Patente por Meses - Total:'+resp.data.cp_total;
      //   this.chartOptionsSV.title.text = 'Violaciones al Estatuto por Meses - Total:' +resp.data.sv_total;
      //  //Created getNumberOfBoats
      //   this.ti = resp.data.ti;
      this.numberOfArtesanales = resp.data.barcos_artesanales;
      this.numberOfIndustriales = resp.data.barcos_industriales;
      this.numberOfFishermanArt = resp.data.pescadores_artesanales;
      this.numberOfFishermanInd = resp.data.pescadores_industrial;
      this.numberOfFishermanDep = resp.data.pescadores_deportiva;
      /*

      /*let x;
        for (x in resp.data.tia){
          this.months.push(x.fecha);
        }*/
      /*let x;
        for (x in resp.data.tia){
          
          this.chartOptions.series[0].data.push(x);
        }*/
    });
  },
  data: () => ({
    // chartOptionsIT: {
    //   chart: {
    //       type: 'column'
    //   },
    //   title: {
    //       text: 'Inspecciones Técnicas por Meses'
    //   },
    //     xAxis: {
    //         type: 'category',
    //         title: {
    //             text: 'Meses'
    //         }
    //     },
    //     yAxis: {
    //         title: {
    //             text: 'Cantidad de Inspecciones Técnicas'
    //         }

    //     },
    //     legend: {
    //     enabled: false
    // },
    //     series: [
    //       {
    //         data: []
    //       }
    //     ]
    //   },
    //   chartOptionsCT: {
    //   chart: {
    //       type: 'column'
    //   },
    //   title: {
    //       text: 'Conceptos Técnicos por Meses'
    //   },
    //     xAxis: {
    //         type: 'category',
    //         title: {
    //             text: 'Meses'
    //         }
    //     },
    //     yAxis: {
    //         title: {
    //             text: 'Cantidad de Conceptos Técnicos'
    //         }

    //     },
    //     legend: {
    //     enabled: false
    // },
    //     series: [
    //       {
    //         data: []
    //       }
    //     ]
    //   },
    //   chartOptionsCP: {
    //   chart: {
    //       type: 'column'
    //   },
    //   title: {
    //       text: 'Certificados de Patente por Meses'
    //   },
    //     xAxis: {
    //         type: 'category',
    //         title: {
    //             text: 'Meses'
    //         }
    //     },
    //     yAxis: {
    //         title: {
    //             text: 'Cantidad de Certificados de Patente'
    //         }

    //     },
    //     legend: {
    //     enabled: false
    // },
    //     series: [
    //       {
    //         data: []
    //       }
    //     ]
    //   },
    //   chartOptionsSV: {
    //   chart: {
    //       type: 'column'
    //   },
    //   title: {
    //       text: 'Violaciones al Estatuto por Meses'
    //   },
    //     xAxis: {
    //         type: 'category',
    //         title: {
    //             text: 'Meses'
    //         }
    //     },
    //     yAxis: {
    //         title: {
    //             text: 'Cantidad de Violaciones al Estatuto'
    //         }

    //     },
    //     legend: {
    //     enabled: false
    // },
    //     series: [
    //       {
    //         data: []
    //       }
    //     ]
    //   },
    // options1: options1,
    // options2: options2,
    // options3: options3,
    numberOfArtesanales: 0,
    numberOfIndustriales: 0,
    numberOfFishermanArt: 0,
    numberOfFishermanInd: 0,
    numberOfFishermanDep: 0,
    tia: [],
  }),
};
</script>

<style lang="scss" scoped>
.back {
  background-image: url("./../assets/media/rpg/logo-rgp-login.png");
  background-size: 100% 100%;
}
</style>
